import type { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { PageType, theme, useResponsive } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { Grid, Pagination } from "swiper/modules";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { hundredTopTablesDiscoveryWidgetQuery$key } from "scmp-app/queries/__generated__/hundredTopTablesDiscoveryWidgetQuery.graphql";

import ImageLogo from "./logo.png";
import {
  BottomContainer,
  Container,
  Disclaimer,
  Header,
  ItemContainer,
  Logo,
  MoreOnButton,
  StyledHundredTopTables,
  StyledHundredTopTablesFakeItem,
  StyledSwiper,
  StyledSwiperSlide,
} from "./styles";

type Props = {
  className?: string;
  reference: hundredTopTablesDiscoveryWidgetQuery$key;
};

export const HundredTopTablesDiscoveryWidget: FunctionComponent<Props> = ({
  className,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment hundredTopTablesDiscoveryWidgetQuery on Query {
        hundredTopTablesQueue: queue(filter: { name: "section_top_517759" }) {
          items(first: 5) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...hundredTopTablesContent
                }
              }
            }
          }
        }
        hundredTopTablesSection: section(filter: { entityId: "517759" }) {
          ...entityLink
        }
      }
    `,
    reference,
  );

  const currentPageType = useCurrentPageType();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"));

  const pgtype = useMemo(() => {
    switch (currentPageType) {
      case PageType.Section:
      case PageType.SubSection:
        return "section";
      case PageType.Topic:
      case PageType.TopicCollection:
      case PageType.Topics:
        return "topic";
    }
  }, [currentPageType]);

  const renderedItems = useMemo(() => {
    const nodes = data?.hundredTopTablesQueue?.items?.edges ?? [];
    const results: EmotionJSX.Element[] = [<StyledHundredTopTablesFakeItem key={0} />];

    nodes.forEach(({ node }) => {
      results.push(<StyledHundredTopTables key={node.entityId} reference={node} />);
    });

    return results;
  }, [data?.hundredTopTablesQueue?.items?.edges]);

  const renderSwiper = useCallback(
    () => (
      <StyledSwiper
        grid={{
          fill: "row",
          rows: 2,
        }}
        modules={[Pagination, Grid]}
        pagination={true}
        spaceBetween={20}
      >
        {renderedItems.map((item, index) => (
          <StyledSwiperSlide key={index}>{item}</StyledSwiperSlide>
        ))}
      </StyledSwiper>
    ),
    [renderedItems],
  );

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "100_top_tables",
        pgtype,
      }}
    >
      <Container className={className}>
        <Header>highlights from</Header>
        <Logo $aspectRatio="auto" $objectFit="contain" disableLinkingData src={ImageLogo.src} />

        <ItemContainer>{isMobile ? renderSwiper() : renderedItems}</ItemContainer>

        <BottomContainer>
          <MoreOnButton reference={data.hundredTopTablesSection}>
            More from 100 top tables
          </MoreOnButton>
          <Disclaimer>Content from SCMP Specialist Publication</Disclaimer>
        </BottomContainer>
      </Container>
    </BaseLinkContextProvider>
  );
};
