import type { FunctionComponent } from "react";

import { BaseImage } from "scmp-app/components/common/base-image";
import { useResponsiveImage } from "scmp-app/components/common/base-image/hooks";

import CoverImageLandscape from "./cover-image-landscape.jpg";
import CoverImageSquare from "./cover-image-square.jpg";
import { Container, CoverImage, Headline } from "./styles";

type Props = {
  className?: string;
};

export const HundredTopTablesFakeItem: FunctionComponent<Props> = ({ className }) => {
  const responsiveVariant = {
    desktopUp: {
      aspectRatio: "3/2",
      url: CoverImageLandscape.src,
      width: 540,
    },
    mobileUp: {
      aspectRatio: "3/2",
      url: CoverImageLandscape.src,
      width: 540,
    },
    tabletUp: {
      aspectRatio: "1/1",
      url: CoverImageSquare.src,
      width: 768,
    },
  };
  const { bindResponsiveAspectRatio } = useResponsiveImage(responsiveVariant);
  const targetLink =
    "https://100toptables.scmp.com/?utm_source=scmp&utm_medium=discovery_module&utm_campaign=100_top_tables";

  return (
    <Container className={className}>
      <CoverImage>
        <a href={targetLink} rel="noreferrer" target="_blank">
          <BaseImage
            $objectFit="cover"
            alt="100 Top Tables 2024 Restaurant Directory"
            responsiveVariant={responsiveVariant}
            src={CoverImageLandscape.src}
            {...bindResponsiveAspectRatio}
            lazyload
          />
        </a>
      </CoverImage>

      <Headline>
        <a href={targetLink} rel="noreferrer" target="_blank">
          100 Top Tables 2024 Restaurant Directory
        </a>
      </Headline>
    </Container>
  );
};

HundredTopTablesFakeItem.displayName = "HundredTopTablesFakeItem";
