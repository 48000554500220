import styled from "@emotion/styled";
import { fontMixtaSharp, theme } from "@product/scmp-sdk";

export const Container = styled.div`
  display: flex;
  flex-flow: column;

  ${theme.breakpoints.only("tablet")} {
    grid-row: 1 / span 4;
    grid-column: 1 / 1;
  }
`;

export const CoverImage = styled.div`
  inline-size: 100%;
  aspect-ratio: 3/2;
`;

export const Headline = styled.div`
  margin-block-start: 24px;

  ${theme.breakpoints.only("tablet")} {
    margin-block-start: 12px;
  }

  a {
    color: #ffffff;
    font-weight: 400;
    font-size: 26px;
    font-family: ${fontMixtaSharp};
    font-style: normal;
    line-height: 110%;

    ${theme.breakpoints.only("tablet")} {
      font-size: 24px;
      line-height: 120%;
    }
  }
`;
